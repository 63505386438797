.container {
  background-color: #171336;
}

.text {
  max-width: 279px;
  font-size: 10px;
  line-height: 120%;

  @media screen and (max-width: 991px) {
    max-width: 100%;
  }
}

.navigation {
  & > *:hover {
    z-index: 1;
  }
}

.item {
  height: 81px;

  &.getInTouch {
    max-width: 500px;

    @media screen and (max-width: 991px) {
      max-width: 100%;
    }
  }

  &.paragraph {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
}

.link {
  width: 94px;

  &.central {
    margin-left: -1px;
    margin-right: -1px;
  }
}