*, ::before, ::after {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media screen and (max-height: 750px) and (min-width: 1024px){
    zoom: 0.9;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

div#root {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.ff-poppins {
  font-family: Poppins, sans-serif;
}

.ff-triakis {
  font-family: TriakisFont, sans-serif;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-24 {
  font-size: 24px;
}

.fs-32 {
  font-size: 32px;
}

.fs-48 {
  font-size: 48px;
}

.fs-64 {
  font-size: 64px;
}

.mt-40 {
 margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mx-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.ls-1 {
  letter-spacing: 1px;
}

.text-title {
  font-family: TriakisFont, sans-serif;
  font-weight: 300;
  font-size: 48px;
  color: #beeb17;
  text-shadow: 0 0 4px #beeb17;

  @media screen and (max-width: 991px) {
    font-size: 32px;
  }
}