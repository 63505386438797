@mixin transition($duration: 200ms) {
  transition: all ease $duration;
}

.button {
  border: 1px solid rgba(255, 255, 255, 0.4);
  background-color: #171336;
  font-weight: 650;
  line-height: 85%;
  color: #beeb17;
  @include transition();

  &:hover, &.active {
    border: 1px solid #beeb17;
    box-shadow: inset 0 0 16px #beeb17;
    color: #beeb17;
    @include transition();
  }
}