@mixin transition($style: none, $duration: 100ms) {
  transition: $style ease $duration;
}

.container {
  background-color: #161335;
  padding: 135px 0 135px 60px;

  @media screen and (max-width: 991px) {
    padding: 80px 40px;
    height: 541px;
  }
}

.backdrop {
  top: 0;
  left: 0;
}

.video {
  object-fit: cover;
  object-position: center;
}

.fill {
  height: 50%;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);;
}

.animatedText {
  width: 100px;
  right: 75px;

  @media screen and (max-width: 767px) {
    right: 24px;
  }

  &.animatedText1 {
    top: 80px;
  }

  &.animatedText2 {
    bottom: 80px;
  }
}

.title {
  width: 550px;
  margin-left: -60px;

  @media screen and (max-width: 991px) {
    width: 400px;
    margin-left: -44px;
  }

  @media screen and (max-width: 767px) {
    width: 300px;
    margin-left: -32px;
  }
}

.description {
  font-size: 68px;
  line-height: 120%;
  max-width: 560px;

  @media screen and (max-width: 1199px) {
    font-size: 38px;
    max-width: 450px;
  }

  @media screen and (max-width: 991px) {
    font-size: 32px;
  }
}

.divider {
  top: 0;
  right: 559px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.4);
  @include transition(right);

  @media screen and (max-width: 991px) {
    right: 159px;
    @include transition(right);
  }
}