.container {
  background: #171336;
}

.top {
  padding: 140px 60px 80px 60px;

  .title {
    font-style: normal;
    line-height: 85%;

    @media screen and (max-width: 991px) {
      margin-left: 24px;
      margin-right: 24px;
    }
  }

  .text {
    font-style: normal;
    font-size: 10px;
    line-height: 145%;

    @media screen and (max-width: 991px) {
      margin-left: 40px;
      margin-right: 40px;
    }
  }
}

.tabs {
  .tab {
    width: 25%;
    min-height: 80px;

    &:hover {
      color: #beeb17 !important;
    }

    @media screen and (max-width: 991px) {
      min-height: 44px;
    }
  }
}

.content {
  padding-top: 80px;
  padding-bottom: 80px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.contentBackground {
  top: 0;
  left: 0;
  background: linear-gradient(95.79deg, #9A00EB 0%, rgba(154, 0, 235, 0) 100%);
  opacity: 0.2;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.contentLabel {
  font-style: normal;
  line-height: 85%;
}

.contentText {
  font-style: normal;
  line-height: 145% !important;
}

.contentImage {
  width: 140px;
}