.container {
  background: rgba(23, 19, 54, 0.8);
  backdrop-filter: blur(8px);
}

.modal {
  background-color: #171336;
}

.button {
  border-radius: 0 !important;
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: #ffffff;
  transition: all ease 100ms;

  &:hover {
    color: #beeb17;
    border: 1px solid #beeb17;
    box-shadow: inset 0 0 16px #beeb17;
    transition: all ease 100ms;
  }
}