.container {
  background: #171336;
}

.top {
  padding: 140px 60px 80px 60px;

  .title {
    font-style: normal;
    line-height: 85%;

    @media screen and (max-width: 991px) {
      margin-left: 24px;
      margin-right: 24px;
    }
  }

  .text {
    font-style: normal;
    font-size: 10px;
    line-height: 145%;

    @media screen and (max-width: 991px) {
      margin-left: 40px;
      margin-right: 40px;
    }
  }
}

.bg {
  background-image: url("./bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-width: 991px) {
    background: none;
  }
}

.bgMobile {
  @media screen and (max-width: 991px) {
    background-image: url("./bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.content {
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.descriptionContainer {
  padding: 80px 185px 150px 90px;
  border-left: 1px solid rgba(255, 255, 255, 0.4);

  @media screen and (max-width: 991px) {
    padding: 40px;
    border-left: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
}

.description {
  font-style: normal;
  font-size: 12px;
  line-height: 145%;
  padding: 0;
  margin: 0;
}

.mapItem {
  margin-top: 80px;
}

.addressItem {
  margin-top: 55px;
  margin-bottom: 40px;
}

.location {
  font-style: normal;
  font-size: 24px;
  line-height: 85%;
}

.addressLine {
  font-style: normal;
  font-size: 16px;
  line-height: 85%;
}