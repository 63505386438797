.header {
  height: 81px;
  top: 0;
  left: 0;
  background: #171336;
  z-index: 1054;

  @media screen and (max-width: 991px) {
    height: 60px;
  }
}

.wrapper {
  flex: 1;
  padding-left: 60px;
  padding-right: 60px;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);

  img {
    width: 155px;
  }

  @media screen and (max-width: 991px) {
    padding-left: 24px;
    padding-right: 24px;

    img {
      width: 120px;
    }
  }
}

.button {
  width: 280px;

  @media screen and (max-width: 991px) {
    width: 160px;
  }
}

.menu {
  top: 81px;
  left: 0;
  height: calc(100vh - 81px);

  @media screen and (max-width: 991px) {
    top: 60px;
    height: calc(100vh - 60px);
  }
}

.desktopMenu {
  top: 81px;
  width: 560px;
  left: calc(100% - 560px);
  background: rgba(23, 19, 54, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-top: 0;
  backdrop-filter: blur(8px);

  max-height: 100vh;
  height: 100%;

  @media screen and (min-height: 784px) {
    max-height: 783.5px;
  }

  .routes {
    //height: 702.5px; // 620.5px;
    padding: 0 80px 0;
    margin: auto 0;

    @media screen and (max-width: 1199px) {
      height: 512.5px;
      padding: 70px;
    }

    .route {
      font-style: normal;
      font-weight: 500;
      font-size: 64px;
      line-height: 85%;
      color: #beeb17;
      transition: color ease 200ms;

      &:hover {
        text-shadow: 0 0 4px #beeb17;
        transition: color ease 200ms;
      }
    }
  }

  .links {
    min-height: 81px;
    margin-bottom: -1px;
    margin-right: -1px;

    .empty {
      height: 1px;
      border-top: 1px solid rgba(255, 255, 255, 0.4);
    }

    .link {
      max-width: 94px;
      min-height: 81px;

      &:hover {
        z-index: 2;
      }

      &.central {
        margin-left: -1px;
        margin-right: -1px;
      }
    }
  }
}

.mobileMenu {
  top: 60px;
  left: 0;
  height: calc(100vh - 60px);

  .routes {
    padding-top: 65px;
    background: rgba(23, 19, 54, 0.8);
    backdrop-filter: blur(8px);

    .route {
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: 85%;
      text-align: center;
      color: #beeb17;
      transition: color ease 200ms;

      &:hover {
        text-shadow: 0 0 4px #beeb17;
        transition: color ease 200ms;
      }
    }
  }

  .mobileGetInTouchLink {
    min-height: 81px;
    max-height: 81px;
    font-size: 24px;
  }

  .mobileLink {
    min-height: 113px;
    max-height: 113px;

    &:hover {
      z-index: 2;
    }

    &.central {
      margin-left: -1px;
      margin-right: -1px;
    }
  }
}
