.container {
  background: #171336;
}

.titleContainer {
  margin-top: 150px;
  margin-bottom: 80px;
}

.title {
  font-style: normal;
  font-size: 48px;
  line-height: 85%;
  color: #beeb17;
}

.text {
  font-style: normal;
  font-size: 10px;
  line-height: 145%;

  @media screen and (max-width: 991px) {
    margin-left: 40px;
    margin-right: 40px;
  }
}


.mt18 {
  margin-top: 18px !important;

  @media screen and (max-width: 767px) {
    margin-top: 24px !important;
  }
}

.mt40 {
  margin-top: 40px !important;

  @media screen and (max-width: 767px) {
    margin-top: 24px !important;
  }
}

.mt100 {
  margin-top: 100px !important;
}

.mb150 {
  margin-bottom: 150px !important;
}

.videoWrap {
  overflow: hidden;
  display: block;
  height: 100%;
  max-height: 895px;
}

.video {
  z-index: 1;
  object-fit: fill;
  margin-bottom: -10px;
  object-position: center;
}

.animatedText {
  width: 100px;

  &.animatedText1 {
    top: 80px;
    left: 60px;

    @media screen and (max-width: 991px) {
      top: 60px;
      left: 40px;
    }

    @media screen and (max-width: 767px) {
      top: 16px;
      left: 16px;
    }
  }

  &.animatedText2 {
    bottom: 80px;
    right: 60px;

    @media screen and (max-width: 991px) {
      bottom: 60px;
      right: 40px;
    }

    @media screen and (max-width: 767px) {
      bottom: 16px;
      right: 16px;
    }
  }
}

.animatedFuture {
  //width: ;
}

.content {
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.descriptionContainer {
  padding: 80px 185px 150px 90px;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
}

.description {
  font-style: normal;
  font-size: 12px;
  line-height: 145%;
  padding: 0;
  margin: 0;
}

.textarea {
  box-sizing: border-box;
  min-height: 180px;
}

.height80px {
  min-height: 80px;
  max-height: 80px;
}