.footer {
  background: #171336;
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.first {
  padding-left: 60px;

  .logo {
    width: 155px;
  }

  .navigation {
    margin-top: 32px;
    margin-left: 400px;

    .navigationLink {
      font-style: normal;
      font-size: 16px;
      line-height: 166%;

      span {
        color: #ffffff;
        transition: color ease 200ms;

        &:hover {
          color: #beeb17;
          transition: color ease 200ms;
        }
      }
    }

    @media screen and (max-width: 1199px) {
      margin-left: 100px;
    }
  }

  .contacts {
    padding: 40px;
    width: calc(94px * 3);
    border-left: 1px solid rgba(255, 255, 255, 0.4);

    .text {
      font-style: normal;
      font-size: 16px;
      line-height: 85%;
    }
  }
}

.second {
  height: 81px;
  margin-bottom: -1px;

  .rights {
    padding-left: 80px;
    padding-right: 40px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);

    .text {
      font-style: normal;
      font-size: 12px;
      line-height: 135%;
    }
  }

  .link {
    width: 94px;
  }
}

.mobileLogo {
  width: 120px;
}

.mobileContacts {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);

  .links {
    margin-top: -1px;
    margin-bottom: -1px;
    margin-right: -1px;
    max-width: 80px;

    .link {
      &:hover {
        z-index: 2;
      }

      &.central {
        margin-top: -1px;
        margin-bottom: -1px;
      }
    }
  }
}

.mobileRights {
  padding: 16px 26px 16px 16px;
}