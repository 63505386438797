.textarea {
  height: 100%;
  outline: none;
  padding: 14px 24px;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  resize: none;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.1);
  transition: border-color ease 100ms;

  &.focused {
    border: 1px solid #ffffff;
    transition: border-color ease 100ms;
  }
}