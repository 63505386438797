.container {
  border: 1px solid rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.1);
  transition: border-color ease 100ms;

  &.focused {
    border: 1px solid #ffffff;
    transition: border-color ease 100ms;
  }
}

.input {
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 14px 24px;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}

.indicator {
  width: 8px;
  height: 44px;

  &.success {
    background-color: #beeb17;
  }

  &.error {
    background-color: #eb5f00;
  }
}