@mixin transition($property: all, $duration: 100ms) {
  transition: $property ease $duration;
}

.container {
  padding-top: 240px;
  padding-bottom: 100px;
  background: #171336;
  overflow: hidden;

  @media screen and (max-width: 991px) {
    padding-top: 120px;
    padding-bottom: 100px;
  }

  @media screen and (max-width: 425px) {
    padding-bottom: 0;
  }
}

.top {
  z-index: 1;
  padding: 0 60px;

  .title {
    font-style: normal;
    line-height: 85%;

    @media screen and (max-width: 991px) {
      margin-left: 24px;
      margin-right: 24px;
    }
  }

  .text {
    font-style: normal;
    font-size: 10px;
    line-height: 145%;

    @media screen and (max-width: 991px) {
      margin-left: 40px;
      margin-right: 40px;
    }
  }
}

.table {
  z-index: 1;
  margin-top: 80px;

  .item {
    z-index: 1;
    overflow: hidden;
    transition: all 300ms ease-out;
    box-sizing: border-box;
    // =================================================================================================================
    //@include transition(all);
    margin-top: 0 !important;
    height: 169.19px;
    //max-height: 200px;
    //max-height: 1000px;

    // =================================================================================================================

    @media screen and (max-width: 767px) {
      transition: all 450ms ease-out;
      height: 90px;
    }

    &:hover {
      z-index: 3;

      & > div:nth-child(1) {
        background: #171336;
        border-color: #beeb17 !important;
        box-shadow: inset 0 0 16px #beeb17;
        @media screen and (max-width: 767px) {
          max-height: 90px;
        }

        //@include transition(all);
      }

      & > div:nth-child(2) {
        @media screen and (max-width: 767px) {
          max-height: 90px;
        }

        .inner {
          opacity: 0.2;
        }
      }
    }

    //&.notFirst {
    //  margin-top: -1px;
    //}

    & > div {
      border-color: rgba(255, 255, 255, 0.4) !important;
      //@include transition(border-color);
    }

    .arrow {
      //@include transition(all);
    }

    .number {
      font-style: normal;
      line-height: 85%;
      font-size: 32px;

      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }

    & > div:not(:nth-child(2)) {
      padding: 70px 0 !important;

      @media screen and (max-width: 767px) {
        padding: 32px 0 !important;
      }
    }

    & > div:nth-child(2) {
      @media screen and (max-width: 767px) {
        border-right: 1px solid transparent !important;
      }

      .inner {
        background: linear-gradient(95.79deg, #0cdeeb 0%, rgba(12, 222, 235, 0) 100%);
        opacity: 0;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        //@include transition(opacity);
      }

      .content {
        padding: 40px;

        @media screen and (max-width: 767px) {
          padding: 16px;
        }

        .rowAnimation {
          width: 120px;
          max-width: 120px;
          min-width: 120px;
          height: 80px;
          margin-right: 40px;

          @media screen and (max-width: 767px) {
            width: 56px;
            max-width: 56px;
            min-width: 56px;
            height: 56px;
          }
        }

        .rowPath {
          width: 150px;
          max-width: 150px;
          min-width: 150px;
          font-style: normal;
          font-size: 32px;
          line-height: 85%;
          margin-top: 30px;

          @media screen and (max-width: 767px) {
            width: 80px;
            max-width: 80px;
            min-width: 80px;
            font-size: 20px;

            margin-top: 16px;
          }
        }

        .rowDescription {
          width: 360px;
          max-width: 360px;
          min-width: 360px;
          margin-top: 40px;
          margin-right: 40px;

          p {
            font-style: normal;
            line-height: 145%;
          }
        }

        .rowList {
          width: 320px;
          max-width: 320px;
          min-width: 320px;
          margin-top: 40px;

          span {
            font-style: normal;
            line-height: 200%;
          }
        }
      }
    }

    &.selected {
      z-index: 2;
      // =================================================================================================================
      height: 400px;

      @media screen and (max-width: 767px) {
        height: 700px;
        border-bottom: 1px solid white;
      }

      //transition: max-height ease 1s;
      // =================================================================================================================

      .arrow {
        transform: rotateX(180deg);
      }

      & > div {
        border-color: #ffffff !important;
        //@include transition(border-color);
      }

      & > div:nth-child(1) {
        background: #171336;
        border-color: #beeb17 !important;
        box-shadow: inset 0 0 16px #beeb17;
      }

      & > div:nth-child(2) {
        border-color: #ffffff !important;

        @media screen and (max-width: 767px) {
          border-right: 1px solid transparent !important;
        }

        .inner {
          opacity: 0.2;
          //@include transition(opacity);
        }
      }
    }
  }

  .mobileInner {
    top: 0;
    left: 0;
    background: linear-gradient(95.79deg, #0cdeeb 0%, rgba(12, 222, 235, 0) 100%);
    opacity: 0.2;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    @include transition(opacity);
  }

  .mobileContent {
    padding: 16px 40px;
  }
}

.gradient {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(180deg, rgba(23, 19, 54, 0.1) 0%, #171336 100%);
}

.resetTop {
  top: 0 !important;
}

.parallax {
  z-index: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;

  @media screen and (max-width: 1199px) {
    $width: 1360px;

    width: $width;
    left: calc((#{$width} - 100vw) / -2);
  }

  @media screen and (max-width: 991px) {
    $width: 1600px;

    width: $width;
    left: calc((#{$width} - 100vw) / -2);
  }

  @media screen and (max-width: 767px) {
    $width: 1300px;

    width: $width;
    left: calc((#{$width} - 100vw) / -2);
  }
}

.collapse:not(.show) {
  display: flex !important;
  min-height: 100% !important;
}

.serviceBg {
  z-index: 0;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  & .wrap {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & .image {
      position: absolute;
      width: 100%;
      left: 0;
      transition: 1000ms ease-in-out;

    }
  }

  .bg {
    height: 100%;
    width: 100%;

    & > img {
      width: 100%;
    }
  }

  .city {
    height: 100%;
    top: 10%;

    & > img {
      width: 100%;
    }
  }

  .train {
    width: 100%;
    height: 100%;
    top: 30%;

    & > img {
      width: 100%;
    }
  }

  .building {
    height: 100%;

    top: 35%;
    display: flex;
    justify-content: end;
  }

  .noise {
    z-index: 3;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background: url('noise-min.png');
  }

  .shine {
    z-index: 2;
    position: absolute;
    left: 0;
    background-repeat: no-repeat;
    background-position: top;
    background-image: url('shine.png');
    top: -450px;
    bottom: 0;
    width: 100%;
  }

  .dark {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background: rgba(#171336, 0.8)
  }

  @media screen and (max-width: 2222px) {
    .city {
      height: 100%;
      top: 10%;

      & > img {
        width: 100%;
      }
    }

    .train {
      width: 100%;
      height: 90%;
      top: 20%;

      & > img {
        width: 100%;
      }
    }

    .building {
      height: 100%;

      top: 25%;
      display: flex;
      justify-content: end;

      & > img {
        object-fit: contain;
        object-position: top;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .shine {
      z-index: 2;
      position: absolute;
      left: 0;
      background-repeat: no-repeat;
      background-position: top;
      background-image: url('shine.png');
      bottom: 0;
      width: 100%;
    }

    .city {
      height: 100%;
      top: 9%;

      & > img {
        width: 100%;
      }
    }

    .train {
      width: 100%;
      height: 100%;
      top: 16%;

      & > img {
        width: 100%;
      }
    }

    .building {
      height: 100%;

      top: 20%;
      display: flex;
      justify-content: end;

      & > img {
        width: 30%;
        object-fit: contain;
        object-position: top;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .city {
      height: 100%;
      top: 4%;

      & > img {
        width: 100%;
      }
    }

    .train {
      width: 100%;
      height: 100%;
      top: 7%;

      & > img {
        width: 100%;
      }
    }

    .building {
      height: 100%;

      top: 10%;
      display: flex;
      justify-content: end;

      & > img {
        width: 30%;
        object-fit: contain;
        object-position: top;
      }
    }
  }
}


