@mixin transition($property: all, $duration: 100ms) {
  transition: $property ease $duration;
}

.container {
  background-image: url("./light.png");
  background-repeat: no-repeat;
  background-position: top;
  background-color: #171336;
}

.bg {
  background-color: rgba(17, 17, 18, 0.5);
}

.grid {
  background-image: url("./grid.png");
}

.top {
  padding: 240px 60px 0 60px;

  @media screen and (max-width: 991px) {
    padding: 120px 60px 0 60px;
  }

  .title {
    font-style: normal;
    line-height: 85%;

    @media screen and (max-width: 991px) {
      margin-left: 24px;
      margin-right: 24px;
    }
  }

  .text {
    font-style: normal;
    font-size: 10px;
    line-height: 145%;

    @media screen and (max-width: 991px) {
      margin-left: 40px;
      margin-right: 40px;
    }
  }
}

.table {
  margin-top: 80px;
  margin-bottom: 120px;

  .item {
    z-index: 1;

    &:hover {
      z-index: 3;

      & > div:nth-child(1) {
        border-color: #beeb17 !important;
        box-shadow: inset 0 0 16px #beeb17;
        @include transition(all);
      }

      & > div:nth-child(2) {
        .inner {
          opacity: 0.2;
        }

        .content .rowPath > span {
          color: #beeb17 !important;
        }
      }
    }

    &.notFirst {
      margin-top: -1px;
    }

    & > div {
      border-color: rgba(255, 255, 255, 0.4) !important;
    }

    //.arrow {
    //  @include transition(all);
    //}

    & > div:not(:nth-child(2)) {
      padding: 90px 0 !important;

      @media screen and (max-width: 767px) {
        padding: 45px 0 !important;
      }
    }

    & > div:nth-child(2) {
      @media screen and (max-width: 767px) {
        border-right: 1px solid transparent !important;
      }

      .inner {
        background: linear-gradient(95.79deg, #0CDEEB 0%, rgba(12, 222, 235, 0) 100%);
        opacity: 0;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        //@include transition(opacity);
      }

      .content {
        padding: 80px 40px 80px 40px;

        @media screen and (max-width: 767px) {
          padding: 32px 16px 32px 16px;
        }

        .rowPath, .rowType, .rowLanguages {
          font-style: normal;

          & > span {
            height: 28px;
            font-style: normal;
          }

          .data div .element {
            &::before {
              position: absolute;
              content: "";
              display: block;
              width: 4px;
              height: 11px;
              top: 6px;
              left: -12px;
              background-image: url("./arrow-right.svg");
            }
          }
        }

        .rowPath {
          width: 250px;

          @media screen and (max-width: 991px) {
            width: auto;

            & > span {
              font-size: 20px !important;
            }
          }

          & > span {
            font-size: 32px;
            line-height: 85%;
          }

          .data {
            margin-top: 80px;
          }
        }

        .rowType {
          height: 28px;
          width: 360px;

          @media screen and (max-width: 991px) {
            width: auto;
          }

          & > span {
            margin-top: 10px;
            font-size: 16px;
            line-height: 85%;
          }

          .data {
            margin-top: 83px;
          }
        }

        .rowLanguages {
          width: 500px;

          @media screen and (max-width: 991px) {
            width: auto;
          }

          & > span {
            margin-top: 10px;
            font-size: 16px;
            line-height: 85%;
          }

          .data {
            margin-top: 100px;
          }
        }

        .mobileRowElement {
          font-style: normal;
          font-size: 10px;
          line-height: 85%;
        }

        //.achievement {
        //  max-width: 95px;
        //}

        .achievement:not(:nth-child(1)) {
          margin-left: 65px;
        }
      }
    }

    &.selected {
      z-index: 2;

      .rowPath {
        & > span {
          color: #beeb17 !important;
        }
      }

      .arrow {
        transform: rotateX(180deg);
        //@include transition(all);
      }

      & > div {
        border-color: #ffffff !important;
        //@include transition(border-color);
      }

      & > div:nth-child(1) {
        //background: #171336;
        border-color: #beeb17 !important;
        box-shadow: inset 0 0 16px #beeb17;
      }

      & > div:nth-child(2) {
        border-color: #ffffff !important;

        @media screen and (max-width: 767px) {
          border-right: 1px solid transparent !important;
        }

        .inner {
          opacity: 0.2;
          //@include transition(opacity);
        }
      }
    }
  }

  .mobileInner {
    top: 0;
    left: 0;
    background: linear-gradient(95.79deg, #0CDEEB 0%, rgba(12, 222, 235, 0) 100%);
    opacity: 0.2;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    //@include transition(opacity);
  }

  .mobileContent {
    padding: 16px 40px;
  }
}

.gradient {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(180deg, rgba(23, 19, 54, 0.1) 0%, #171336 100%);
}

.mobileChallengeSolutionTitle {
  font-style: normal;
  font-size: 20px;
  line-height: 85%;
}

.mobileChallengeSolutionText {
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
}

.mobileElement {
  font-style: normal;
  font-size: 10px;
  line-height: 15px;

  &::before {
    position: absolute;
    content: "";
    display: block;
    width: 4px;
    height: 11px;
    top: 6px;
    left: -12px;
    background-image: url("./arrow-right.svg");
  }
}

.achievement {
  max-width: 95px;
}