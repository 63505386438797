@mixin transition($duration: 200ms) {
  transition: all ease $duration;
}

.button {
  position: relative;
  background-color: #ffffff;
  @include transition();

  & > span {
    font-weight: 650;
    line-height: 19px;
    color: #171336;
  }

  &:hover {
    &:before, &:after {
      position: absolute;
      content: "";
      top: 0;
      height: 0;
      width: 0;
    }

    &:before {
      left: 0;
      border-top: 81px solid rgba(23, 19, 54, 1.8);
      border-right: 50px solid transparent;
    }

    &:after {
      right: 0;
      border-bottom: 81px solid rgba(23, 19, 54, 1.8);
      border-left: 50px solid transparent;
    }
  }
}
